import React from 'react'
import { useScrollOver } from '@gluedigital/scrollastic'
import Feature from './Feature'

import './Features.sass'
import type { Feature as FeatureType } from 'src/types'

const Features = ({ scrollable }: { scrollable?: any }) => {
  const features: FeatureType[] = ['strategies', 'powerful', 'secure']
  const section = useScrollOver('#landing-features', scrollable)
  return (
    <section
      id="landing-features"
      className={'landing-section ' + (section > 0.3 ? 'enter' : '')}
    >
      {features.map((feature) => (
        <Feature key={feature} feature={feature} scrollable={scrollable} />
      ))}
    </section>
  )
}

export default Features
