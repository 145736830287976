import { useEffect } from 'react'
import { isReactRef, isWindow } from './utils/checks'
import type { Handler, Target } from './types'

/**
 * To keep all updates from different hook calls in a single batched update,
 * we need to wrap all the setState calls with ReactDOM.batchedUpdates().
 *
 * To make this possible, we manage event subscriptions ourselves, by creating
 * a single addEventListener subscription, which calls every listener while
 * batching updates.
 *
 * We keep the subscriptions linked to each scrollable through a WeakMap, and
 * offer methods for subscribing and desubscribing.
 */

type GlobalTarget = HTMLElement | (Window & typeof globalThis)

const scrollableMap = new WeakMap<GlobalTarget, Handler[]>()

const getCurrentScroll = (target: HTMLElement | Window) =>
  !isWindow(target)
    ? target.scrollTop
    : document.documentElement.scrollTop || document.body.scrollTop

const globalHandler = (target: GlobalTarget) => (e: Event) => {
  const handlers = scrollableMap.get(target) || []
  const currentScroll = getCurrentScroll(target)
  handlers.forEach((h) => h(currentScroll, e))
}

const addScrollListener = (target: GlobalTarget, handler: Handler) => {
  if (!scrollableMap.has(target)) {
    scrollableMap.set(target, [handler])
    target.addEventListener('scroll', globalHandler(target))
  } else {
    scrollableMap.get(target)?.push(handler)
  }
}

const removeScrollListener = (target: GlobalTarget, handler: Handler) => {
  const handlers = scrollableMap.get(target) || []
  const newHandlers = handlers.filter((h) => h !== handler)
  if (newHandlers.length) {
    scrollableMap.set(target, newHandlers)
  } else {
    target.removeEventListener('scroll', globalHandler(target))
    scrollableMap.delete(target)
  }
}

export const useScrollEvents = (
  target: Target,
  handler: Handler,
  keys: unknown[] = [],
) => {
  useEffect(() => {
    const finalTarget = isReactRef(target) ? target.current : target

    const evtTarget = finalTarget || window

    // Fix initial state after first render
    handler(getCurrentScroll(evtTarget))

    // Deal with subscriptions
    addScrollListener(evtTarget, handler)
    return () => removeScrollListener(evtTarget, handler)
  }, keys)
}
