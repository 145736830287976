import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Landing from './Landing/Landing'
import NotFound from './NotFound/NotFound'
import { useSelector } from 'react-redux'
import Meta from 'src/components/Meta/Meta'

const App = () => {
  const currentLang = useSelector((s: any) => s.intl.locale)

  return (
    <>
      <Meta />
      <main id="app" className={`language-${currentLang}`}>
        <div className="pages-transition-group">
          <Switch>
            <Route path="/" exact>
              <Landing />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </div>
      </main>
    </>
  )
}

export default App
