import React, { Suspense } from 'react'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import Spinner from 'src/components/Spinner/Spinner'
import { ExploreProduct } from 'src/types'
import ProductInfo from './Info/ProductInfo/ProductInfo'
import NoApr from './Info/NoApr/NoApr'

import './ProductCard.sass'

interface RecipeCardProps {
  product: ExploreProduct
  show: boolean
}

const ProductCard = ({ product, show }: RecipeCardProps) => {
  const { color, name, title, type } = product
  const lowerName = name.toLocaleLowerCase()
  const imageUrl = `/images/landing/product-card/${lowerName}.jpg`
  const iconUrl = `/images/landing/product-icon/${lowerName}.png`

  return (
    <article className={`product-card ${color}`}>
      <header
        style={{
          backgroundImage:
            'url(' +
            (imageUrl ?? '/images/landing/recipe-card/default.jpg') +
            ')'
        }}
      />
      <h1 className="mono-space">{title}</h1>
      <img className="product-token" src={iconUrl} alt="product token" />
      <footer>
        <ErrorBoundary fallback={<NoApr />}>
          {show ? (
            <Suspense fallback={<Spinner />}>
              <ProductInfo product={product} />
            </Suspense>
          ) : null}
        </ErrorBoundary>
        <span className={`badge ${type}`}>{type}</span>
      </footer>
    </article>
  )
}

export default ProductCard
