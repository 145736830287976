import React from 'react'
import { FormattedMessage } from 'react-intl'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'

import './GoToLeaderboard.sass'

const GoToLeaderboard = () => {
  return (
    <ErrorBoundary>
      <div id="go-to-leaderboard">
        <div className="wrapper">
          <p>
            <FormattedMessage id="go-to-leaderboard.text" />
          </p>
          <a
            className="button"
            rel="noopener noreferrer"
            target="_blank"
            href="https://leaderboard-s2.autolayer.io/"
          >
            <FormattedMessage id="go-to-leaderboard.link" />
          </a>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default GoToLeaderboard
