import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import LanguageSelectorDialog from 'src/components/LanguageSelectorDialog/LanguageSelectorDialog'
import language from 'src/static/images/network-selector/global.svg'
import './NetworkSelector.sass'

const NetworkSelector = () => {
  // const dispatch = useDispatch()
  const [chooseLanguage, setChooseLanguage] = useState<boolean>(false)

  return (
    <>
      <details className="network-selector">
        <summary>
          <img loading="lazy" src={language} alt="globe symbol" />
          <span className="icon icon-angle_down" />
        </summary>
        <section>
          {/* <h1>
            <FormattedMessage id="network" />
          </h1>
          <ul className="network-list">
            {networks.map((network) => {
              return (
                <li
                  className={`network-item ${selectedNetworkName === network.toLowerCase() ? 'selected' : ''}`}
                  key={network}
                  onClick={() => handleNetwork(network.toLowerCase())}
                >
                  <img
                    loading="lazy"
                    src={networkImages[network.toLowerCase()]}
                    alt=""
                  />
                  <span>{networkChoice[network]}</span>
                </li>
              )
            })}
          </ul> */}
          <ul className="language-list">
            <li onClick={() => setChooseLanguage(!chooseLanguage)}>
              <img loading="lazy" src={language} alt="globe symbol" />
              <span>
                <FormattedMessage id="language-selector.text" />
              </span>
            </li>
          </ul>
        </section>
      </details>
      <LanguageSelectorDialog
        chooseLanguage={chooseLanguage}
        setChooseLanguage={setChooseLanguage}
      />
    </>
  )
}

export default NetworkSelector
