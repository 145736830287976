import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'
import ExploreContainer from './ExploreContainer'

import featuredProducts from 'src/data/products/featuredProducts.json'
import trendingProducts from 'src/data/products/trendingProducts.json'

import './Explore.sass'

const Explore = ({ scrollable }: { scrollable?: any }) => {
  const START_SHOWING_COMPONENT: number = 0.15 // useScrollOver returns a number > 0 when the component chosen starts to be shown. 0.3 == 30%

  const [isInfoRequested, setIsInfoRequested] = useState<boolean>(false) // This state is used to verify that we only request info the first time that we scroll
  const section = useScrollOver('#landing-explore', scrollable)

  useEffect(() => {
    if (section > 0) setIsInfoRequested(true)
  }, [section])

  return (
    <section
      id="landing-explore"
      className={
        'landing-section ' + (section > START_SHOWING_COMPONENT ? 'enter' : '')
      }
    >
      <div className="container">
        <h1>
          <MarkdownMessage id="landing-explore.title" />
        </h1>
        <p className="mono-space">
          <span>
            <FormattedMessage id="landing-explore.text" />
          </span>
        </p>
        <section id="featured-section">
          {/* <h3 className="mono-space">
            <span>
              <FormattedMessage id="landing-explore.featured.title" />
            </span>
          </h3> */}
          <ExploreContainer
            products={featuredProducts}
            maxItems={3}
            show={isInfoRequested}
          />
        </section>
        <section id="trending-section">
          {/* <h3 className="mono-space">
            <span>
              <FormattedMessage id="landing-explore.trending.title" />
            </span>
          </h3> */}
          <ExploreContainer
            products={trendingProducts}
            maxItems={3}
            show={isInfoRequested}
          />
        </section>
        <a href="https://app.autolayer.io/" className="explore-more">
          <span className="icon icon-external_window" />
          <span>
            <FormattedMessage id="landing-explore.more" />
          </span>
        </a>
      </div>
    </section>
  )
}

export default Explore
