import React from 'react'
import { Helmet } from 'react-helmet-async'

const Analytics = () => {
  const scriptText = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-5QWCQ5YXGH');
  `

  return (
    <Helmet>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-5QWCQ5YXGH"
      ></script>
      <script>{scriptText}</script>
    </Helmet>
  )
}

export default Analytics
