import { RefObject, useEffect, useState } from 'react'

export function useInViewport(element: RefObject<HTMLElement>) {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  useEffect(() => {
    const ref = element.current

    const cbFunction = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries
      setIsVisible(entry.isIntersecting)
    }

    const observer = new IntersectionObserver(cbFunction, {
      root: document.querySelector('#landing'),
      rootMargin: '0px',
      threshold: 0
    })

    if (ref) observer.observe(ref)

    return () => {
      if (ref) observer.unobserve(ref)
    }
  }, [element])

  return isVisible
}
