import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { API_URL } from 'src/constants'
import { TokensInfo } from 'src/types'

export function useTokensLst(): TokensInfo[] {
  const route = 'lst'
  const tokens: TokensInfo[] = useFetch(API_URL + route)
  return tokens
}

export function useTokensLrst(): TokensInfo[] {
  const route = 'lrst'
  const tokens: TokensInfo[] = useFetch(API_URL + route)
  return tokens
}
