import { useFetch } from '@gluedigital/ruse-fetch-extras'
import { API_URL } from 'src/constants'
import { ReducedCamelotPoolInfo } from 'src/types'

export const useSingleCamelotPool = (
  poolAddress: string
): ReducedCamelotPoolInfo => {
  const pool: ReducedCamelotPoolInfo = useFetch(
    API_URL + 'camelot-pool/' + poolAddress
  )
  return pool
}
