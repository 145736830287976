import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
// import MarkdownMessage from '@gluedigital/markdown-message'
import LanguageSelector from 'src/components/LanguageSelector/LanguageSelector'
import LaunchFast from '../LaunchFast/LaunchFast'
import SocialLinks, { LinkType } from 'src/components/SocialLinks/SocialLinks'

import logo from 'src/static/images/logos/autolayer-logo-dark.svg'
// import psh from 'src/static/vectors/peckshield/psh-dark.svg'
import './Footer.sass'

const Footer = ({ darkMode = false }: { darkMode?: boolean }) => {
  return (
    <footer id="colophon" className={darkMode ? 'dark-theme' : 'light-theme'}>
      <div className="container">
        <section className="row row-top">
          <Link className="link footer-logo" to="/">
            <img loading="lazy" src={logo} alt="autolayer logo" />
          </Link>
          <SocialLinks type={LinkType.Social} />
        </section>
        <section className="row row-center">
          <div className="columns-wrapper">
            <section className="column info">
              <h1>
                <FormattedMessage id="footer.info.title" />
              </h1>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://docs.autolayer.io/autolayer"
              >
                <FormattedMessage id="footer.info.docs" />
              </a>
              {/* <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://github.com/Tortle-Ninja/"
              >
                <FormattedMessage id="footer.info.github" />
              </a> */}
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href=" https://docs.autolayer.io/autolayer/welcome/audit-report"
              >
                <FormattedMessage id="footer.info.audit" />
              </a>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://www.figma.com/file/4rUg3L1LitVcK2x60Pk3N8/AutoLayer-Brandbook?type=design&node-id=8-1984&mode=design&t=7GvEFAPnn7V73t8z-0"
              >
                <FormattedMessage id="footer.info.media" />
              </a>
            </section>
            <section className="column community">
              <h1>
                <FormattedMessage id="footer.community.title" />
              </h1>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://t.me/+DlInSb5slQUwOWI0"
              >
                <FormattedMessage id="footer.community.telegram" />
              </a>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://discord.gg/autolayer-899973803239686174"
              >
                <FormattedMessage id="footer.community.discord" />
              </a>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://twitter.com/AutoLayer"
              >
                X (<FormattedMessage id="footer.community.twitter" />)
              </a>
            </section>
            <section className="column legal">
              <h1>
                <FormattedMessage id="footer.legal.title" />
              </h1>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://docs.autolayer.io/autolayer/latest-app-infos/privacy-policy"
              >
                <FormattedMessage id="footer.legal.privacy" />
              </a>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://docs.autolayer.io/autolayer/latest-app-infos/privacy-policy/terms-of-use"
              >
                <FormattedMessage id="footer.legal.terms" />
              </a>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://docs.autolayer.io/autolayer/latest-app-infos/privacy-policy/cookie-policy"
              >
                <FormattedMessage id="footer.legal.cookies" />
              </a>
            </section>
            <section className="column help">
              <h1>
                <FormattedMessage id="footer.help.title" />
              </h1>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://discord.gg/autolayer-899973803239686174"
              >
                <FormattedMessage id="footer.help.talk-support" />
              </a>
              <a
                rel="noopener noreferrer"
                className="link"
                target="_blank"
                href="https://docs.autolayer.io/autolayer/welcome/faqs-and-troubleshooting/"
              >
                FAQs
              </a>
            </section>
          </div>
          <section className="column actions">
            <LanguageSelector />
            <LaunchFast darkMode={darkMode} />
          </section>
        </section>
        <section className="row row-bottom">
          {/* <div className="row">
            <span className="audited-by">
              <FormattedMessage id="footer.audited-by" />
            </span>
            <img loading="lazy" src={psh} alt="peckShield logo" />
            <a
              href="https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-TortleNinja-v1.0.pdf"
              target="_blank"
              className="button"
              rel="noopener noreferrer"
            >
              <MarkdownMessage id="footer.peckshield" />
            </a>
          </div> */}
          <span>
            <FormattedMessage
              id="footer.copyright"
              defaultMessage="© 2024 by AutoLayer. All rights Reserved."
            />
          </span>
        </section>
      </div>
    </footer>
  )
}

export default Footer
