import React, { CSSProperties, RefObject, useEffect, useState } from 'react'
import { IPlayerProps, Player } from '@lottiefiles/react-lottie-player'
import { useInViewport } from './useIsInside'

interface ShyPlayerProps {
  animation: () => Promise<any>
  playerProps: Omit<IPlayerProps, 'src'>
  size?: Pick<CSSProperties, 'width' | 'height'>
}

interface AnimationFallbackProps {
  size?: Pick<CSSProperties, 'width' | 'height'>
}

const defaultSize = { width: '100%', height: '100%' }

function AnimationFallback({ size }: AnimationFallbackProps) {
  return <div style={size ?? defaultSize} />
}

function ShyPlayer({ animation, playerProps, size }: ShyPlayerProps) {
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    const load = async () => {
      const data = await animation()

      setData(data.default)
    }

    void load()
  }, [animation])

  return <Player src={data} {...playerProps} style={size} />
}

export default function ShyPlayerWrapper({
  element,
  playerProps,
  size,
  animation
}: ShyPlayerProps & {
  element: RefObject<HTMLElement>
}) {
  const isInViewport = useInViewport(element)

  return isInViewport ? (
    <ShyPlayer playerProps={playerProps} animation={animation} size={size} />
  ) : (
    <AnimationFallback size={size} />
  )
}
