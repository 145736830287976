import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setLang } from 'universal-scripts'
import langChoices from 'src/locales/choices.json'

import './LanguageSelector.sass'

interface LanguageSelectorProps {
  iconRight?: boolean
  contracted?: boolean
}
const LanguageSelector = (props: LanguageSelectorProps) => {
  const { iconRight, contracted } = props
  const dispatch = useDispatch()
  const languages = Object.keys(langChoices)
  const currentLang = useSelector((s: any) => s.intl.locale)
  const handleLang = (e) => {
    const language = e.target.value
    dispatch(setLang(language))
    document.cookie = 'lang=' + language
  }

  return (
    <div className="language-selector-wrapper">
      <select
        defaultValue={currentLang}
        onChange={handleLang}
        id="id_select"
        className="language-selector"
        name="language-selector"
      >
        {languages.map((language) => (
          <option key={language} value={language}>
            {contracted ? language : langChoices[language]}
          </option>
        ))}
      </select>
      <span className={`icon icon-angle_${iconRight ? 'right' : 'down'}`} />
    </div>
  )
}

export default LanguageSelector
