import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'

import { useSubscribe } from 'src/hooks/useSubscribe'

import './About.sass'

const About = ({ scrollable }: { scrollable?: any }) => {
  const section = useScrollOver('#landing-about', scrollable)
  const [email, setEmail] = useState<string>('')
  const subscribe = useSubscribe()
  const [mode, setMode] = useState<'sending' | 'done' | 'error' | null>(null)

  const applyHandler = (e) => {
    e.preventDefault()
    setMode('sending')
    void subscribe(email)
      .then(() => setMode('done'))
      .catch(() => {
        setMode('error')
        setTimeout(() => {
          setMode(null)
          setEmail('')
        }, 3000)
      })
  }

  return (
    <section
      id="landing-about"
      className={'landing-section ' + (section > 0.3 ? 'enter' : '')}
    >
      <div className="container">
        <div className="about-card">
          <h1 className="section-title">
            <span>
              <MarkdownMessage id="landing.about.title" />
            </span>
          </h1>
          <p className="recipes-text mono-space">
            <FormattedMessage id="landing.about.text" />
          </p>
          <form onSubmit={applyHandler} className={mode ?? ''}>
            <div className="row">
              <FormattedMessage id="email-placeholder">
                {(text) => (
                  <input
                    required
                    placeholder={text as unknown as string}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}
              </FormattedMessage>
              <button disabled={!!mode} className="submit-about">
                <FormattedMessage id={mode ?? 'subscribe'} />
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default About
