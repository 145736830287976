import React, { RefObject, useRef } from 'react'
// import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'
import ShyPlayerWrapper from '../../../components/ShyPlayer/ShyPlayer'

import './TokenSection.sass'

const animation = async () =>
  await import('src/components/ShyPlayer/animations/token.json')

const TokenSection = ({
  scrollable
}: {
  scrollable?: RefObject<HTMLElement>
}) => {
  const section = useScrollOver('#landing-token', scrollable)

  const ref = useRef<HTMLElement>(null)

  return (
    <section
      id="landing-token"
      className={'landing-section ' + (section > 0.15 ? 'enter' : '')}
      ref={ref}
    >
      <div className="container">
        <div className="split">
          <div className="item left">
            <h1>
              <MarkdownMessage id="landing.token.title" />
            </h1>
            <p className="mono-space">
              <FormattedMessage id="coming-soon" />
              {/* <MarkdownMessage id="landing.token.text" /> */}
            </p>
            {/* <Link className="button" to="/token-details">
              <FormattedMessage id="more-info" />
            </Link> */}
          </div>
          <div className="item right">
            <ShyPlayerWrapper
              animation={animation}
              element={ref}
              playerProps={{
                id: 'token-animation',
                autoplay: true,
                loop: true
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default TokenSection
