import React from 'react'
import { useTokensLrst } from 'src/hooks/useApi'
import { ExploreProduct, ReducedCamelotPoolInfo, TokensInfo } from 'src/types'
import { useSingleCamelotPool } from 'src/hooks/usePools'

import './ProductInfo.sass'

const ProductInfo = ({ apr }: { apr: string }) => {
  return (
    <section className="product-info">
      <div
        className={`item item-apr ${Number(apr) > 0 ? 'success' : 'faliure'}`}
      >
        <span>APY: </span>
        <span>{!isNaN(Number(apr)) ? Number(apr) : '--'}%</span>
      </div>
    </section>
  )
}

const ProductInfoDefi = ({ product }: { product: ExploreProduct }) => {
  const url = product.url
  const data: string[] = url?.split('/')
  const address = data[data?.length - 1]
  const pool: ReducedCamelotPoolInfo = useSingleCamelotPool(address)
  return (
    <section className="product-info">
      <div className="item item-apr">
        <span>APY: </span>
        <span>{pool?.apy}%</span>
      </div>
    </section>
  )
}

const ProductInfoLRT = ({ product }: { product: ExploreProduct }) => {
  const lrstTokens: TokensInfo[] = useTokensLrst()
  const token = lrstTokens?.find(
    (t) => t?.id?.toLowerCase() === product.name.toLowerCase()
  )
  return <ProductInfo apr={token?.apr ?? '0'} />
}

const ProductInfoWrapper = ({ product }: { product: ExploreProduct }) => {
  if (product.type === 'defi') {
    return <ProductInfoDefi product={product} />
  } else if (product.type === 'lrt') {
    return <ProductInfoLRT product={product} />
  } else return <></>
}
export default ProductInfoWrapper
