import React from 'react'
import { FormattedMessage } from 'react-intl'

export default function About() {
  return (
    <div id="header-about">
      <a
        href="https://docs.autolayer.io/autolayer/welcome/faqs-and-troubleshooting"
        target="_blank"
        rel="noreferrer"
      >
        <FormattedMessage id="landing.navigation.about.faq" />
      </a>
      <a
        href="https://docs.autolayer.io/autolayer/latest-app-infos/rewards-and-programs"
        target="_blank"
        rel="noreferrer"
      >
        <FormattedMessage id="landing.navigation.about.opportunities" />
      </a>
      <a
        href="https://discord.gg/autolayer-899973803239686174"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage id="landing.navigation.about.support" />
      </a>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href="/pdf/privacy-policy.pdf"
      >
        <FormattedMessage id="landing.navigation.about.privacy-policy" />
      </a>
      <a
        href="https://drive.google.com/drive/folders/1E40nNNRd2lHnkgf3-LW3sQ5qZ-5LaxaI?usp=sharing"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage id="landing.navigation.about.mediakit" />
      </a>
    </div>
  )
}
