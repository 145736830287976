import { useAtTop, useScrollSelector } from '@gluedigital/scrollastic'
import { RefObject, useEffect, useRef, useState } from 'react'

type Section =
  | 'intro'
  | 'explore'
  | 'features'
  | 'token'
  | 'community'
  | 'about'
  | 'partners'
  | 'hero-banner'
  | 'video'

type Colors = 'black' | 'white' | 'blue'

const sectionClass: Record<Section, Colors> = {
  about: 'black',
  community: 'blue',
  explore: 'black',
  features: 'white',
  intro: 'white',
  partners: 'black',
  token: 'black',
  'hero-banner': 'black',
  video: 'black'
}

export function useHeaderScroll(
  scrollable: RefObject<HTMLDivElement>,
  headerRef: RefObject<HTMLElement>
) {
  const isTop = useAtTop(scrollable)
  const actualSection = useScrollSelector('.landing-section', {
    scrollable,
    boundary: headerRef.current?.clientHeight ?? 0
  })

  const section = actualSection?.replace('landing-', '') as Section

  const lastScroll = useRef<number>(scrollable.current?.scrollTop ?? 0)
  const [show, setShow] = useState<boolean>(true)

  useEffect(() => {
    setShow(!(section === 'features' || section === 'video'))

    const element = scrollable.current

    const handleScroll = () => {
      const newScroll = element?.scrollTop ?? 0

      if (section === 'features' || section === 'video') {
        setShow(newScroll < lastScroll.current)
      }

      lastScroll.current = newScroll
    }

    element?.addEventListener('scroll', handleScroll)

    return () => element?.removeEventListener('scroll', handleScroll)
  }, [scrollable, section])

  return {
    class: isTop || !actualSection ? 'default' : sectionClass[section],
    show
  }
}
