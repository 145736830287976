import React from 'react'
import { FormattedMessage } from 'react-intl'

import './NoApr.sass'

const NoApr = () => {
  return (
    <div className="recipe-item-performance-bottom">
      <FormattedMessage id="recipe-item.apr-error" />
    </div>
  )
}

export default NoApr
