import React, { RefObject } from 'react'
import { useScrollOver } from '@gluedigital/scrollastic'

import './Parallax.sass'
import clouds from 'src/static/images/landing/parallax/webp/clouds.webp'
import planet from 'src/static/images/landing/parallax/webp/planet.webp'
import landR from 'src/static/images/landing/parallax/webp/land-right.webp'
import landL from 'src/static/images/landing/parallax/webp/land-left.webp'
import float from 'src/static/images/landing/parallax/webp/float.webp'
import cloudsDefault from 'src/static/images/landing/parallax/png/clouds-min.png'
import planetDefault from 'src/static/images/landing/parallax/png/planet-min.png'
import landRDefault from 'src/static/images/landing/parallax/png/land-right-min.png'
import landLDefault from 'src/static/images/landing/parallax/png/land-left-min.png'
import floatDefault from 'src/static/images/landing/parallax/png/float-min.png'

interface ParallaxProps {
  scrollable: RefObject<HTMLDivElement>
}

const Parallax = ({ scrollable }: ParallaxProps) => {
  const parallax = useScrollOver('#layer1', scrollable)
  const position = (multiplier, c = -45) => c + parallax * multiplier + '%'
  return (
    <div id="parallax">
      <div className="layer layer-top" id="layer4">
        <picture>
          <source srcSet={planet} type="image/webp" />
          <img src={planetDefault} alt="Planet" />
        </picture>
      </div>
      <div
        style={{ bottom: position(30, -29) }}
        className="layer layer-bottom"
        id="layer3"
      >
        <picture>
          <source srcSet={clouds} type="image/webp" />
          <img src={cloudsDefault} alt="Clouds" />
        </picture>
      </div>
      <div
        style={{ left: '-17.6%', bottom: position(35, -10) }}
        className="layer layer-bottom"
        id="layer2-1"
      >
        <picture>
          <source srcSet={float} type="image/webp" />
          <img src={floatDefault} alt="float" />
        </picture>
      </div>
      <div
        style={{ bottom: position(35, -30) }}
        className="layer layer-bottom"
        id="layer2"
      >
        <picture>
          <source srcSet={float} type="image/webp" />
          <img src={floatDefault} alt="float" />
        </picture>
      </div>
      <div
        style={{ bottom: position(47) }}
        className="layer layer-bottom"
        id="layer1"
      >
        <picture>
          <source srcSet={landR} type="image/webp" />
          <img src={landRDefault} alt="Land" />
        </picture>
      </div>
      <div
        style={{ bottom: position(47) }}
        className="layer layer-bottom"
        id="layer0"
      >
        <picture>
          <source srcSet={landL} type="image/webp" />
          <img src={landLDefault} alt="Land" />
        </picture>
      </div>
    </div>
  )
}

export default Parallax
