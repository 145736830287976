import React from 'react'
import { FormattedMessage } from 'react-intl'

import './LaunchFast.sass'

const LaunchFast = ({
  text = 'default-text',
  darkMode = false
}: {
  text?: string
  darkMode?: boolean
}) => {
  return (
    <a
      className={`launch-button ${darkMode ? 'dark-theme' : 'light-theme'}`}
      href="https://app.autolayer.io/"
    >
      <FormattedMessage id={`launch-button.${text}`} />
    </a>
  )
}

export default LaunchFast
