import React, { RefObject, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'
import LaunchFast from 'src/components/LaunchFast/LaunchFast'
import Parallax from './Parallax/Parallax'

import './HeroBanner.sass'
import cube from 'src/static/images/landing/parallax/webp/cube.webp'
import cubeDefault from 'src/static/images/landing/parallax/png/cube-min.png'

interface HeroBannerProps {
  scrollable: RefObject<HTMLDivElement>
}

const HeroBanner = ({ scrollable }: HeroBannerProps) => {
  const element = useRef<HTMLElement>(null)

  return (
    <section id="landing-hero-banner" className="landing-section" ref={element}>
      <Parallax scrollable={scrollable} />
      <div className="container">
        <div className="content content-left">
          <div className="wrapper">
            <h1>
              <FormattedMessage id="landing.hero-banner.title" />
            </h1>
            <p>
              <MarkdownMessage id="landing.hero-banner.text" />
            </p>
            <LaunchFast text="landing-hero-banner" />
          </div>
        </div>
        <div className="content content-right">
          <picture id="cube">
            <source srcSet={cube} type="image/webp" />
            <img src={cubeDefault} alt="Autolayer Cube" />
          </picture>
        </div>
      </div>
    </section>
  )
}

export default HeroBanner
