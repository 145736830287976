import React, { RefObject, useEffect, useRef, useState } from 'react'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'
import poster from 'src/static/images/landing/hero-banner/header-cube.webp'
import fallbackPosterPNG from 'src/static/images/landing/hero-banner/header-cube.png'
import videoMp4 from 'src/static/videos/hero-banner.mp4'
import videoWebm from 'src/static/videos/hero-banner.webm'

import './VideoIntro.sass'

interface VideoSectionProps {
  scrollable: RefObject<HTMLDivElement>
}

const VideoIntro = ({ scrollable }: VideoSectionProps) => {
  const section = useScrollOver('#landing-video', scrollable)
  const [step, setStep] = useState<number>(-1)
  const firstElement = useRef<HTMLDivElement>(null)
  const firstPlaceholderElement = useRef<HTMLDivElement>(null)
  const lastPlaceholderElement = useRef<HTMLDivElement>(null)
  const steps = useRef<HTMLDivElement[]>([])

  useEffect(() => {
    const first = firstElement.current
    const elements = steps.current
    const lastPlaceholder = lastPlaceholderElement.current
    const firstPlaceholder = firstPlaceholderElement.current
    if (!first || !elements || !lastPlaceholder || !firstPlaceholder) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.boundingClientRect.bottom <= 0) {
            setStep((prev) => prev + 1)
          } else if (
            entry.isIntersecting &&
            entry.intersectionRect.bottom > 0 &&
            entry.intersectionRect.bottom !== entry.rootBounds?.bottom
          ) {
            setStep((prev) => prev - 1)
          }
        })
      },
      {
        root: scrollable.current
      }
    )
    observer.observe(firstPlaceholder)
    observer.observe(first)
    observer.observe(lastPlaceholder)
    elements.forEach((element) => observer.observe(element))

    return () => {
      observer.unobserve(firstPlaceholder)
      observer.unobserve(first)
      observer.unobserve(lastPlaceholder)
      elements.forEach((element) => observer.unobserve(element))
    }
  }, [scrollable])

  return (
    <section
      id="landing-video"
      className={
        'video-section landing-section ' +
        (section > 0.05 && section < 0.95 ? 'enter' : '')
      }
    >
      <div className="container">
        <div className="split placeholder" ref={firstPlaceholderElement}>
          <div className="item left"></div>
          <div className="item right">
            <div className="video-wrapper">
              <div className="veil" />
              <video
                id="video-intro"
                loop
                autoPlay
                playsInline
                muted
                controls={false}
                className={`video-step-${step}`}
              >
                <picture>
                  <source type="image/webp" srcSet={poster} />
                  <img src={fallbackPosterPNG} alt="Fallback poster" />
                </picture>
                <source src={videoMp4} type="video/quicktime" />
                <source src={videoWebm} type="video/webm" />
              </video>
            </div>
          </div>
        </div>
        <div className="split" id="landing-video-step-0" ref={firstElement}>
          <div className={`item left ${step === 0 ? 'show' : ''}`}>
            <h1>
              <MarkdownMessage id="landing.intro.title" />
            </h1>
            <p className="mono-space">
              <MarkdownMessage id="landing.intro.text" />
            </p>
          </div>
          <div className="item right" />
        </div>
        {[...Array(5)].map((_, i) => {
          return (
            <div
              key={i + 1}
              className="split"
              id={`landing-banner-step-${i + 1} `}
              ref={(element) => (element ? (steps.current[i] = element) : null)}
            >
              <div className={`item left ${step === i + 1 ? 'show' : ''}`}>
                <div className="content">
                  <p className="mono-space">
                    <MarkdownMessage id={`landing.video-step-${i + 1}.text`} />
                  </p>
                </div>
              </div>
              <div className="item right" />
            </div>
          )
        })}
        <div className="split placeholder" ref={lastPlaceholderElement}>
          <div className="item left" />
          <div className="item right" />
        </div>
      </div>
    </section>
  )
}

export default VideoIntro
