import { useFetchUncachedCb } from '@gluedigital/ruse-fetch-extras'

const baseUrl = 'https://api.hsforms.com/submissions/v3/integration/submit'

const portalId = '144134067'
const formId = 'dccea3cf-618c-4e45-90f6-b0faee592ce0'

export const useSubscribe = () => {
  const fetch = useFetchUncachedCb()
  const sendData = async (value: string) =>
    await fetch<boolean>(`${baseUrl}/${portalId}/${formId}`, {
      method: 'POST',
      body: {
        context: {
          pageUri: window.location.href,
          pageName: document.title
        },
        submittedAt: Date.now(),
        fields: [
          {
            name: 'email',
            value
          }
        ]
      }
    })
  return sendData
}
