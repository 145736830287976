import { useState } from 'react'
import { useScrollEvents } from './batchedScrollEvents'
import {
  ScrollSelectorInterface,
  ScrollSelelectorOptions,
  Target,
} from './types'
import { isReactRef, isWindow } from './utils/checks'

export { useScrollEvents }

const parseCssUnit = (s: string | number): number => {
  if (typeof s === 'number') return s

  if (s.endsWith('px')) {
    return parseFloat(s.substring(0, s.length - 2))
  }
  if (s.endsWith('vh')) {
    const val = parseFloat(s.substring(0, s.length - 2))
    return (val * window.innerHeight) / 100
  }

  return NaN
}

export const useScrollBelow = (px: number, scrollable?: Target): boolean => {
  if (typeof window === 'undefined') return false
  const [isBelow, setBelow] = useState(false)

  useScrollEvents(
    scrollable,
    (currpx) => {
      setBelow(Math.abs(currpx) > px)
    },
    [px, scrollable],
  )

  return isBelow
}

export const useAtTop = (scrollable?: Target): boolean => {
  return !useScrollBelow(0, scrollable)
}

export const useAtBottom = (scrollable?: Target) => {
  const finalTarget = isReactRef(scrollable) ? scrollable.current : scrollable

  const evtTarget = finalTarget || window

  const isWindowObject = isWindow(evtTarget)

  const target = !isWindowObject
    ? evtTarget.scrollHeight - evtTarget.clientHeight
    : (evtTarget.document.scrollingElement?.scrollHeight ?? 0) -
      (evtTarget.document.scrollingElement?.clientHeight ?? 0)

  return useScrollBelow(target - 1, scrollable)
}

export const useScrollBetween = (
  start: number,
  end: number,
  scrollable?: Target,
): number => {
  const [progress, setProgress] = useState(0)

  useScrollEvents(
    scrollable,
    (currpx) => {
      const progress = (currpx - start) / (end - start)
      setProgress(progress < 0 ? 0 : progress > 1 ? 1 : progress)
    },
    [start, end, scrollable],
  )

  return progress
}

export const useScrollOver = (query: string, scrollable?: Target): number => {
  const [progress, setProgress] = useState(0)

  useScrollEvents(
    scrollable,
    () => {
      const element = document.querySelector(query)
      if (element) {
        const pos = element.getBoundingClientRect()
        const wh = window.innerHeight
        if (pos.top > wh) {
          setProgress(0)
        } else if (pos.bottom < 0) {
          setProgress(1)
        } else {
          setProgress((wh - pos.top) / (pos.height + wh))
        }
      }
    },
    [query, scrollable],
  )

  return progress
}

const scrollSelectorDefaults: ScrollSelectorInterface = {
  mapper: (e) => e.id,
  boundary: 0,
  fromBottom: false,
}

export const useScrollSelector = (
  query: string,
  opts: Partial<ScrollSelelectorOptions>,
): string | null => {
  const { mapper, scrollable, boundary, fromBottom } = {
    ...scrollSelectorDefaults,
    ...opts,
  }

  const [section, setSection] = useState<string | null>(null)

  useScrollEvents(
    scrollable,
    () => {
      let boundaryPx = parseCssUnit(boundary)
      if (fromBottom) boundaryPx = window.innerHeight - boundaryPx

      const elements = Array.from(document.querySelectorAll(query))
      const elementPositions = elements.map((e) => e.getBoundingClientRect())
      if (!elements.length) return

      const firstBelow = elementPositions.findIndex((p) => p.top > boundaryPx)
      if (firstBelow === 0) {
        // We didn't reach the first one yet
        setSection(null)
      } else if (firstBelow === -1) {
        // We're at the last one, or past it
        const last = elements.length - 1
        if (elementPositions[last].bottom < boundaryPx) {
          // Past last one
          setSection(null)
        } else {
          // At last one
          const lastElement = elements[last]
          setSection(mapper(lastElement))
        }
      } else {
        // We're at some section
        setSection(mapper(elements[firstBelow - 1]))
      }
    },
    [query, scrollable],
  )

  return section
}
