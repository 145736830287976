import React from 'react'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import ProductCard from 'src/components/ProductCard/ProductCard'
import { ExploreProduct } from 'src/types'

const ExploreItem = ({
  product,
  show
}: {
  product: ExploreProduct
  show: boolean
}) => {
  return (
    <ErrorBoundary fallback={<></>}>
      <a
        className="explore-item"
        href={`https://app.autolayer.io/${product.url}`}
      >
        <ProductCard product={product} show={show} />
      </a>
    </ErrorBoundary>
  )
}

export default ExploreItem
