import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import MarkdownMessage from '@gluedigital/markdown-message'
import ShyPlayerWrapper from 'src/components/ShyPlayer/ShyPlayer'
import type { Feature } from 'src/types'

import './Features.sass'

const powerfulAnimation = async () =>
  await import('src/components/ShyPlayer/animations/features/powerful.json')
const secureAnimation = async () =>
  await import('src/components/ShyPlayer/animations/features/secure.json')
const strategiesAnimation = async () =>
  await import('src/components/ShyPlayer/animations/features/strategies.json')

const Features = ({
  scrollable,
  feature
}: {
  scrollable?: any
  feature: Feature
}) => {
  const element = useRef<HTMLElement>(null)
  const section = useScrollOver(`#landing-feature-${feature}`, scrollable)

  const selectCorrectAnimation = (feature: Feature) => {
    switch (feature) {
      case 'powerful':
        return powerfulAnimation
      case 'secure':
        return secureAnimation
      case 'strategies':
        return strategiesAnimation
    }
  }

  return (
    <article
      id={`landing-feature-${feature}`}
      className={`landing-feature ${feature} ${section > 0.3 ? 'fixed' : ''}`}
      ref={element}
    >
      <div className="split">
        <div className="item left">
          <div className="content-wrapper">
            <h2>
              <MarkdownMessage id={`landing-feature.${feature}.title`} />
            </h2>
            <p className="mono-space">
              <FormattedMessage id={`landing-feature.${feature}.text`} />
            </p>
          </div>
        </div>
        <div className="item right">
          {section > 0.15 && (
            <ShyPlayerWrapper
              animation={selectCorrectAnimation(feature)}
              playerProps={{
                autoplay: true,
                keepLastFrame: true,
                loop: feature === 'strategies',
                id: `${feature}-feature-animation`,
                className: 'feature-animation',
                speed: feature === 'powerful' ? 0.5 : 1
              }}
              element={element}
            />
          )}
        </div>
      </div>
    </article>
  )
}

export default Features
