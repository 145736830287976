import en from './en.json'
import es from './es.json'
import zh from './zh.json'
import ko from './ko.json'
import vi from './vi.json'
import uk from './uk.json'
import fr from './fr.json'
import indonesio from './in.json'
import jp from './jp.json'
import th from './th.json'
import tk from './tk.json'
import ind from './ind.json'
import ru from './ru.json'

export default {
  en,
  es,
  zh,
  vi,
  ko,
  uk,
  fr,
  in: indonesio,
  jp,
  th,
  tk,
  ind,
  ru
}
