import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import SocialLinks, { LinkType } from 'src/components/SocialLinks/SocialLinks'

import community from 'src/data/community.json'

import './Community.sass'

const Community = ({ scrollable }: { scrollable?: any }) => {
  const section = useScrollOver('#landing-community', scrollable)

  return (
    <section
      id="landing-community"
      className={'landing-section ' + (section > 0.3 ? 'enter' : '')}
    >
      <div className="container">
        <div className="community-card">
          <h1 className="section-title">
            <span>
              <FormattedMessage id="landing.community.title" />
            </span>
          </h1>
          <p className="recipes-text mono-space">
            <FormattedMessage id="landing.community.text" />
          </p>
          <div className="community-grid">
            {community.map((c) => (
              <a
                title={c.name}
                key={c.name}
                target="_blank"
                rel="noreferrer noopener"
                className={`community-link ${c.name}`}
                href={c.href}
              >
                <span className={`icon icon-${c.name}`} />
                <h2>
                  <FormattedMessage
                    id={`home.community.card-${c.name}.title`}
                  />
                </h2>
              </a>
            ))}
          </div>
          <SocialLinks type={LinkType.Social} />
        </div>
      </div>
    </section>
  )
}

export default Community
