import React from 'react'
import { FormattedMessage } from 'react-intl'
import About from './About/About'

const Navigation = () => {
  const handleMouseDown = (event: React.MouseEvent<HTMLLIElement>) => {
    if (event.currentTarget === document.activeElement) {
      const t = event.currentTarget
      setTimeout(() => t.blur(), 0)
    }
  }

  return (
    <nav id="landing-navigation">
      <ul>
        <li>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://docs.autolayer.io/"
          >
            <FormattedMessage id="landing-navigation.docs" />
          </a>
        </li>
        <li>
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="https://docs.autolayer.io/autolayer/welcome/audit-report"
          >
            <FormattedMessage id="landing-navigation.audit" />
          </a>
        </li>
        <li>
          <a href="#landing-partners">
            <FormattedMessage id="landing-navigation.partners" />
          </a>
        </li>
        <li>
          <a href="#landing-community">
            <FormattedMessage id="landing-navigation.community" />
          </a>
        </li>
        <li tabIndex={0} onMouseDown={handleMouseDown}>
          <a>
            <FormattedMessage id="landing-navigation.about" />
            <span className="icon icon-angle_down" />
          </a>
          <About />
        </li>
      </ul>
    </nav>
  )
}

export default Navigation
