import React from 'react'
import MarkdownMessage from '@gluedigital/markdown-message'
import { useScrollOver } from '@gluedigital/scrollastic'
import { createSlug } from 'src/utils/createSlug'

import logos from 'src/data/logos.json'
import './Partners.sass'

const Partners = ({ scrollable }: { scrollable?: any }) => {
  const section = useScrollOver('#landing-partners', scrollable)
  return (
    <section
      id="landing-partners"
      className={'landing-section ' + (section > 0.1 ? 'enter' : '')}
    >
      <h1>
        <MarkdownMessage id="landing.partners.title" />
      </h1>
      <div className="container">
        {logos.map((logo, i) => {
          const number = i + 2
          const delay = `${number < 10 ? '0.' + number : ('' + number).slice(0, 1) + '.' + ('' + number).slice(1)}s`
          return (
            <div
              style={{ transitionDelay: delay }}
              key={logo.name}
              className={`logo-card ${createSlug(logo.name)}`}
              title={logo.name}
            >
              <img
                src={`/logos/partners/${logo.src}?`}
                loading="lazy"
                alt={`${logo.name} logo image`}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Partners
