import React from 'react'

import './FallbackProductCard.sass'

const FallbackProductCard = ({ loading = false }: { loading?: boolean }) => {
  return (
    <div className="explore-item explore-fallback-item">
      <article className={`fallback-recipe-card ${loading ? 'loading' : ''}`}>
        <header />
        <div className="fallback description" />
        <footer>
          <div className="fallback info" />
          <div className="fallback tokens" />
        </footer>
      </article>
    </div>
  )
}

export default FallbackProductCard
