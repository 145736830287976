import React from 'react'
import { ExploreProduct } from 'src/types'
import ExploreItem from './ExploreItem'
import FallbackProductCard from 'src/components/ProductCard/Fallback/FallbackProductCard'

interface ExploreContainerProps {
  products: ExploreProduct[]
  maxItems: number
  show: boolean
}

interface ExploreContainerDataProps {
  products: ExploreProduct[]
  maxItems: number
  show: boolean
}

const ExploreContainerData = ({
  products,
  maxItems,
  show
}: ExploreContainerDataProps) => {
  const emptyData = Array(
    maxItems - products.length < 0 ? 0 : maxItems - products.length
  ).fill(null)

  return (
    <>
      {products.map((product, i) => (
        <ExploreItem key={i} product={product} show={show} />
      ))}
      {emptyData.map((_, i) => (
        <FallbackProductCard key={i} />
      ))}
    </>
  )
}

const ExploreContainer = ({
  products,
  maxItems,
  show
}: ExploreContainerProps) => {
  return (
    <div className="recipe-grid">
      <ExploreContainerData
        maxItems={maxItems}
        products={products}
        show={show}
      />
    </div>
  )
}

export default ExploreContainer
